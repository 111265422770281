<template>
  <ContactPage />
</template>

<script>
import ContactPage from "@/components/view/ContactPage";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    ContactPage,
  },
  metaInfo: {
    title: "Welkom bij KantoorFlex | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>