<template>



  <AboutPage />


</template>

<script>

import AboutPage from "@/components/view/AboutPage";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {

    AboutPage,
 
  },
  metaInfo: {
    title: "Over jouwbedrijfsmakelaar | Bedrijfspanden in Nederland ",
    metaInfo:'Over ons bedrijf Jouwbedrijfsmakelaar. Platform voor bedrijfs onroerend goed. Makelaar voor verhuur en verkoop van bedrijfsruimtes en zakelijk vastgoed. ',
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>